#highlightCompletedMatchBet_0 {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #d7d7d7;
}
.BLM-betSlipBox-close {
  background-color: #f5f7ff;
  border-left: 1px solid #d7d7d7;
  min-width: 30px;
  max-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.BLM-betSlip-outcomeOdds {
  display: flex;
  justify-content: space-between;
}
.BLM-betSlip-outcome.truncate {
  font-family: Poppins,sans-serif;
  font-size: 8px;
  font-weight: 600;
}
.BLM-betSlip-market {
  color: #222;
  font-family: Poppins,sans-serif;
  font-size: 10px;
  font-weight: 400;
  letter-spacing: .2px;
  line-height: 1.5;
}
.BLM-betSlip-fixture {
  color: #777;
  font-family: Poppins,sans-serif;
  font-size: 10px;
  line-height: 16px;
}
.BLM-betSlip-acceptOddsChange-container.p-2 {
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
  min-height: 60px;
  padding: 5px 12px;
  align-items: center;
}

.BLM-betSlip-clearAll {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #e03944;
  cursor: pointer;
  text-decoration: underline;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 1.5;
}
.BLM-checkboxLabel {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #222;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 1.5;
}
.main-slip-st {
  background-color: white;
}
.BLM-addSelections-infoMsg {
  padding: 6px 12px;
  text-align: center;
  font-size: 11px;
  background-color: #ebefff;
  color: #e03944;
}
.BLM-stakeBox-container {
  display: flex;
  align-items: center;
  background-color: #d9e1ff;
  padding-left: 12px;
  justify-content: end;
}

.BLM-stakeInputBox.BLM-form-control {
  display: flex;
  justify-content: end;
  align-items: center;
}
.font-cls {
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  color: #222;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 1.5;
}

.BLM-accordion-header.BLM-arrowAfter {
  font-weight: 500;
  padding: 10px 12px;
  line-height: 20px;
  display: flex;
}
.BLM-totalStakeOddsWins-content {
  width: 95%;
}
.BLM-btnPrimary.active {
  background-color: #f39200;
  color: #fff;
}

.BLM-accordion > .BLM-accordion-header.BLM-arrowAfter::after {
  display: flex;
  justify-content: center;
  align-items: center;
}
