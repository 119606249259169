.sc-hKwDye{
    /*min-width: 20px !important;*/
}

.sc-hKwDye{
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.sc-hKwDye{
    padding-left: 5px !important;
    padding-right: 5px !important;
}

.sc-hKwDye div:first-child{
    /*white-space: normal !important;*/
}

.sc-egiyK{
    /*white-space: normal !important;*/
    overflow: initial !important;
    color: #495057!important;
    font-weight: 600;
    font-size: 13px;
}
.pay-btn{
    white-space: nowrap;
    background-color: #37bd7e;
color: white;
border: none;
border-radius: 2px;
height: 25px;
margin: 0px 0 5px 0;
}
.prev-btn{
    white-space: nowrap;
    background-color: #3b8ab9;
color: white;
border: none;
border-radius: 2px;
height: 25px;
margin: 5px 0 0px 0;
}
.modal-content{
    border-radius: 0.4rem;
    border: 1px solid #f6f6f6;
    font-family: "Poppins",sans-serif;
}
.modal-header{
    background-color: rgb(245, 245, 245);
    color: black;
    padding: 1rem;
    border-bottom: 1px solid #eff2f7;
}
h5{
    color: #495057;
}
.h5, h5 {
    font-size: 1.015625rem;
}
[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer;
}
.close {
    background: 0 0;
    border: 0;
    font-size: 10px;
    padding: 1.35rem 1.25rem;
    background: transparent url(data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3E%3C/svg%3E) 50%/1em auto no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    opacity: .5;
    width: 1em;
    height: 1em;
    z-index: 2;
}
.close span {
    display: none;
}
.bg-primary{
    --bs-bg-opacity: 1;
    background-color: #556EE6!important;
}
.card {
    margin-bottom: 24px;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    border: 0 solid #f6f6f6;
}
.card-title {
    font-size: 15px;
    margin: 0 0 7px;
    font-weight: 600;
    color: #495057;
}
.text-white {
    --bs-text-opacity: 1;
    color: rgba(255,255,255,var(--bs-text-opacity))!important;
    color: rgba(var(--bs-white-rgb),var(--bs-text-opacity))!important;
}
.card-subtitle, .card-text:last-child {
    margin-bottom: 0;
}
.card-body {
    padding: 1.25rem;
}
.bg-success{
    background-color: #34C38F!important;
}
.bg-info{
    background-color: #50A5F1!important;
}
.bg-warning{
    background-color: #F1B44C!important;
}
.card-subtitle, .card-text:last-child {
    margin-bottom: 0;
}
.modal-body p{
    font-size: 0.8125rem;
}
.card-header {
    padding: 0.625rem 1.25rem;
}
.border-bottom {
    border-bottom: 1px solid #eff2f7 !important;
}
.bg-light{
    background-color: #EFF2F7!important;

}
.badge {
    display: inline-block;
    padding: 0.25em 0.4em;
    font-size: 54%;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25rem;
}
.badge-soft-dark {
    color: #343a40;
     background-color: rgba(52,58,64,.18);
}
.me-t{
    margin-top: -2px;
}
.btn-info{
    color: #fff;
    background-color: #50a5f1;
    border-color: #50a5f1;
}
.btns{
    font-size: 0.7109375rem;
}
.modal-title{
    color: #495057;
}
.btn-info:active{
    background-color: #448ccd;
    border-color: #4084c1;

}
.mdi-information::before {
    content: "󰋼";
}
/*.mdi-set, .mdi:before {*/
/*    display: inline-block;*/
/*    font: normal normal normal 24px/1 "Material Design Icons";*/
/*    font-size: inherit;*/
/*    text-rendering: auto;*/
/*    line-height: inherit;*/
/*    -webkit-font-smoothing: antialiased;*/
/*    -moz-osx-font-smoothing: grayscale;*/
/*}*/
/*.font-size-18 {*/
/*    font-size: 18px!important;*/
/*}*/
.tooltipCls{
    margin-left: 5px;
    height:15px;
    width: 15px;
}