body.moving {
  cursor: move;
}

.roulette {
  margin: 40px 0px;
  padding: 40px;
  background: #136229;
  display: table;
  position: relative;
  width: 100%;
  /*transform: rotate(90deg);
    margin:300px 0 0 -230px;*/
}

.roulette table {
  float: left;
}

.roulette table,
tr {
  border-collapse: collapse;
  /*border-spacing: 0;*/
}

.roulette td {
  border-spacing: 0;
  border-collapse: collapse;
  border: 2px solid white;
  width: 50px;
  height: 46px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  font-size: 18px;
  color: white;
  white-space: nowrap;
}

/* elements */
.roulette .sector {
  background: #136229;
  color: #fff;
}

.roulette .sector .vt {
  font-size: 15px;
}

.roulette .num {
  font-size: 22px;
}

.roulette .num span {
  display: block;
  transform: rotate(270deg);
}

.roulette .zero {
  width: 70px;
  font-size: 56px;
}

.roulette .empty {
  border: none;
}

/* colors */
.roulette .green {
  background: #136229;
  color: #fff;
}

.roulette .red {
  background: #ff0000;
  color: #fff;
}

.roulette .black {
  background: #000;
  color: #fff;
}

/* cells hovers */
.roulette .sector.hover,
.roulette .num.hover {
  font-size: 26px;
  -moz-box-shadow: inset 0 0 5px #fff, inset 0 0 5px #fff;
  -webkit-box-shadow: inset 0 0 5px #fff, inset 0 0 5px #fff;
  box-shadow: inset 0 0 5px #fff, inset 0 0 5px #fff;
}

.roulette .sector.hover .vt {
  font-size: 17px;
}

.roulette .sector.hover {
  font-size: 22px;
  background: #1c9022 !important;
  color: #fbec0f !important;
}

.roulette .green.hover {
  font-size: 68px;
  background: #3cc042 !important;
  color: #fbec0f !important;
}

.roulette .red.hover {
  background: #ff0000 !important;
  color: #fbec0f !important;
}

.roulette .black.hover {
  background: #000 !important;
  color: #fbec0f !important;
}

/* cells selected */
.roulette .sector.selected,
.roulette .num.selected {
  font-size: 26px;
  -moz-box-shadow: inset 0 0 5px #fff, inset 0 0 5px #fff;
  -webkit-box-shadow: inset 0 0 5px #fff, inset 0 0 5px #fff;
  box-shadow: inset 0 0 5px #fff, inset 0 0 5px #fff;
}

.roulette .sector.selected .vt {
  font-size: 17px;
}

.roulette .sector.selected {
  font-size: 22px;
  background: #1c9022 !important;
  color: #fbec0f !important;
}

.roulette .green.selected {
  font-size: 68px;
  background: #3cc042 !important;
  color: #fbec0f !important;
}

.roulette .red.selected {
  background: #ff0000 !important;
  color: #fbec0f !important;
}

.roulette .black.selected {
  background: #000 !important;
  color: #fbec0f !important;
}

/* orientation */
.roulette .vt {
  display: block;
  transform: rotate(270deg);
  white-space: nowrap;
}

/* controlls */
.roulette .controlls {
  position: relative;
  top: 0;
  left: 0;
  opacity: 0;
}

.roulette .controlls .btnr {
  /*opacity: 0.5;*/
  position: absolute;
  cursor: pointer;
  z-index: 1;
}

/* zero */
.roulette .controlls .btn-zero {
  width: 75px;
  height: 152px;
  z-index: 0;
}

/* positions */
.roulette .controlls .h {
  width: 37px;
  height: 10px;
}

.roulette .controlls .v {
  width: 10px;
  height: 35px;
}

.roulette .controlls .c {
  width: 10px;
  height: 15px;
}

.roulette .controlls .m {
  width: 54px;
  height: 50px;
  margin-top: -7px;
  margin-left: -10px;
  z-index: 0;
}

.roulette .controlls .ms4 {
  width: 216px;
  height: 50px;
  margin-top: -7px;
  margin-left: -10px;
  z-index: 0;
}

.roulette .controlls .ms2 {
  width: 108px;
  height: 50px;
  margin-top: -7px;
  margin-left: -10px;
  z-index: 0;
}

/* rows */
.roulette .controlls .row1 .rh {
  top: -7px;
}

.roulette .controlls .row1 .rm {
  top: 8px;
}

.roulette .controlls .row2 .rh {
  top: 40px;
}

.roulette .controlls .row2 .rm {
  top: 53px;
}

.roulette .controlls .row3 .rh {
  top: 85px;
}

.roulette .controlls .row3 .rm {
  top: 99px;
}

.roulette .controlls .row3 .rb {
  top: 131px;
}

.roulette .controlls .row4 .rm {
  top: 146px;
}

.roulette .controlls .row5 .rm {
  top: 192px;
}

.roulette .controlls .row6 .rm {
  top: 239px;
}

/* cols */
.roulette .controlls .col1 .cv {
  left: 71px;
}

.roulette .controlls .col1 .cm {
  left: 85px;
}

.roulette .controlls .col2 .cv {
  left: 122px;
}

.roulette .controlls .col2 .cm {
  left: 139px;
}

.roulette .controlls .col3 .cv {
  left: 176px;
}

.roulette .controlls .col3 .cm {
  left: 193px;
}

.roulette .controlls .col4 .cv {
  left: 230px;
}

.roulette .controlls .col4 .cm {
  left: 247px;
}

.roulette .controlls .col5 .cv {
  left: 284px;
}

.roulette .controlls .col5 .cm {
  left: 301px;
}

.roulette .controlls .col6 .cv {
  left: 338px;
}

.roulette .controlls .col6 .cm {
  left: 355px;
}

.roulette .controlls .col7 .cv {
  left: 392px;
}

.roulette .controlls .col7 .cm {
  left: 409px;
}

.roulette .controlls .col8 .cv {
  left: 446px;
}

.roulette .controlls .col8 .cm {
  left: 463px;
}

.roulette .controlls .col9 .cv {
  left: 500px;
}

.roulette .controlls .col9 .cm {
  left: 517px;
}

.roulette .controlls .col10 .cv {
  left: 554px;
}

.roulette .controlls .col10 .cm {
  left: 571px;
}

.roulette .controlls .col11 .cv {
  left: 608px;
}

.roulette .controlls .col11 .cm {
  left: 625px;
}

.roulette .controlls .col12 .cv {
  left: 662px;
}

.roulette .controlls .col12 .cm {
  left: 679px;
}

.roulette .controlls .col13 .cm {
  left: 733px;
}

/* others */
.hidden {
  display: none;
}

.background {
  background: #136229 !important;
  border: 0px !important;
  border-radius: 0px !important;
}

span {
  /* color: white; */
}

.DrawIDTitle {
  font-size: 24px;
  font-weight: 600;
  text-transform: uppercase;
  color: #c0c0c0;
  font-family: system-ui;
  margin: 5px;
}

.drawIDNumber {
  display: inline-block;
  font-size: 24px;
  line-height: 15px;
  font-weight: 800;
  color: #f6a623;
  position: relative;
  top: 3px;
  font-family: cursive;
}

.drawTimerDigit {
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.7);
  position: relative;
  float: left;
  margin: 1%;
  width: 20%;
  height: 100%;
  font-size: 50px;
  font-weight: bold;
  top: 15%;
  line-height: 120%;
  border-radius: 6px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: #f6a623 !important;
}

.drawTimerDigit {
  font-size: 50px;
  font-weight: bold;
  line-height: 120%;
  color: #f6a623 !important;
  text-align: center;
}
.blink {
  color: #f6a623 !important;
}
.drawTimer {
  width: 30%;
}
.clockDivider {
  margin-top: 12px;
  float: left;
  display: inline-block;
  position: relative;
  width: 10%;
  height: 100%;
}
.dot.top {
  top: 33%;
}
.dot {
  display: block;
  background: #f5deb8;
  width: 51%;
  height: 17%;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  left: 23%;
}
.dot.bottom {
  bottom: 26%;
}
.dot {
  display: block;
  background: #f5deb8;
  width: 51%;
  height: 17%;
  position: absolute;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  left: 23%;
}
