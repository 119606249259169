.sports-body{
    font-size: 14px;
font-weight: 500;
font-family: "Poppins", sans-serif;
height: min-content;
margin-bottom: 50px;
}
.bg-cls{
    background-color: #eceff1;
    height: 650px;
    
}
.rows-cls{
    min-height: 75px;
    background-color: white;
    font-size: 0.9em
}

.flag-row{
    height: 25px;
}
.rows-head-cls{
    min-height: 50px;
    background-color: white;
    font-size: 0.9em
}
.btn-group > .btn{
    font-size: 12px !important;
}
.btn-group .under-btn,.btn-group .over-btn{
    border: 1px solid #4B5356 !important;
    border-radius: 0.25rem!important;
}
.btn-group1 > .btn {
    font-size: 12px !important;
    width: 50%;

}
.btn-group1{
    display: flex;
    justify-content: end;
}
.dropdown-div{
    position: absolute;
    top: 100%;
    height: 269px;
    overflow: auto;
    width: 94%;
    z-index: 2;
    border: 1px solid rgba(0, 0, 0, 0.04);
    background-color: white;
    box-shadow: 0 16px 24px 2px rgb(0 0 0 / 14%);
    border-radius: 3px;
}

.dropdown-div ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.dropdown-div li {
    /*padding: 8px 12px;*/
}
.dropdown-div li a{
    text-decoration: none;
}
.btn-dropdown{
    background-color: white;
    color:#f20d4e;
    text-decoration: none;
    border-color: #f20d4e;
}
.btn-dropdown:after {
    content: none;
}
.dropdown-division .dropdown-ul{
    height: 269px;
    overflow: auto;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}
.dropdown-division .dropdown-ul-no-result{
    height: unset;
}
.dropdown-division .BLM-btnOdds{
    border: 1px solid #f5f7ff;
    background-color: white;
}
.dropdown-division .BLM-btnOdds.active{
    background-color: #f39200;
    border: 0;
    font-weight: 600;
    color: #fff;

}
.filter-a{
    width: 100%;
    color: unset;
}
.filter-btn-odd .BLM-outcome{
    display: unset!important;
    float: left;
    width: 85%;
}
.filter-btn-odd.BLM-btnOutComeOdds .BLM-odds{
    float: right;
}
.filter-btn-odd.disabled{
    color: #000;
    background-color: #f8f9fa;
}
.filter-a.disabled{
    color: #000;
    background-color: #f8f9fa;
    pointer-events: none;
}