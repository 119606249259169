.main-cls{
    background-color: #ffffff;
    font-family: "Poppins", sans-serif;
    font-size: 14px;

}

.head-row{
    background-color: #ecf0f5;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
  
    font-weight: bold;
}
.custom-border-cls{
    border: 1px solid black;
    border-top: none;
}