th{
    color: #333333;
    font-size: 14px;
font-weight: 500;
font-family: "Poppins", sans-serif;
}
tr{
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}
.text-cls{
    font-size: 14px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
}