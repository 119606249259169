@page {
    size: 72.1mm 210mm;
    margin-right: 5mm;
    margin-left: 5mm;
    margin-top: 0mm;
    margin-bottom: 0mm;
}

body, .heading-style, .subHeading, .main-text, .sub-text, .text, .time, .final-bill {
    font-family: 'Roboto', sans-serif;
    font-weight: 400; /* Ensure Roboto 400 (regular) is used */
}

.heading-style{
    font-size: 58px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    padding-bottom: 10px;
    /* padding-top: 2px; */
    letter-spacing: 3.2px;
}
.subHeading{
    font-size: 50px;
    font-weight: 500;
    text-align: center;
}
.main-text{
    text-align: left;
    font-size: 17px;
}
.sub-text{
    font-weight: bold;
    padding: 0px 3px 0px 3px;
}
.text{
    border-left: 2px solid lightgray;
    padding: 0px 0px 0px 4px;
}
.time{
    display: flex;
    border-left: 4px solid;
    margin: 8px 0px;
    font-size: 12px;
}
.final-bill{
    padding: 1px 0px;
    font-size: 18px;
}
.test{
    background: white;
    margin: 0px 457px 0px 456px;
    width: 10cm;
    min-height: 29.7cm;
}
ul{
    padding-left:unset!important;
    margin-bottom: 6px!important;
}